// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// changed require to import per ruby guide 4-29-2022
//require("@rails/ujs").start()
//require("turbolinks").start()
//require("@rails/activestorage").start()
//require("channels")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
//import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

//require("bootstrap")
import "bootstrap"

// Select2
import select2 from 'select2'; 
import 'select2/dist/css/select2.css';

//import './bootstrap_custom.js'
import '../stylesheets/application'
import 'controllers'

//document.addEventListener("turbolinks:load", () => {
  //$('[data-toggle="tooltip"]').tooltip()
//})

//require("jquery")
require("@nathanvda/cocoon")

require("packs/custom")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import "controllers"
